@use "src/_bg.scss";

@use "src/_btn.scss";
@use "src/_theme.scss";

@use "src/_side.scss";
@use "src/_token.scss";
@use "src/_wallet.scss";
@use "src/_footer.scss";

.App {
  text-align: center;
  padding: 16px;

}
 
.world {
  z-index: 2;

}


body {
  @include theme.textFont;

}
strong, button, input {
  @include theme.altFont;
}


body {
  background: theme.$bg;
  color: theme.$fontColor;
  width: 100%;

  height: 100vh;

  margin: 0;

}





a, a:visited {
  @include theme.gradientText(theme.$theme,theme.$theme1);
  text-decoration: none;
  &:hover {
    @include theme.gradientText(theme.$theme1,theme.$theme);
  }
}







@include btn.Btn;

.navi {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;

  background: rgba(theme.$bg,0.8);
  padding: 8px 16px;
  box-sizing: border-box;

  border: 2px solid rgba(0,0,0,0.4);
  border-top-left-radius: 100vw;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.deli-container {
  border-bottom: 1px solid theme.$pink;
  
  max-width: 800px;
  margin: 0 auto 32px;
  p {
    margin-top: 8px;
    margin-bottom: 16px;
    background: rgba(theme.$bg,0.8);
    padding: 4px 8px;
    box-sizing: border-box;
    border: 2px solid rgba(black,0.4);
    filter: drop-shadow(2px 2px 2px black);
    border-radius: 2px;
  }

}

.connected--false .world {
  display: none;
}


@include token.Token;
@include wallet.Wallet;
@include footer.Footer;
.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  margin: 0;
  font-size: 20px;
}

.focus {
  padding: 32px 64px 64px;
  text-align: left;
  background: rgba(theme.$bg,0.6);
  border: 1px solid rgba(white, 0.4);
  filter: drop-shadow(2px 2px 2px black);
  border-radius: 4px;
  max-width: 1080px;
  margin: 256px 32px  16px;
  a { font-size: 24px;}
  a, p, h2 {filter: drop-shadow(2px 2px 2px black);}
  h2 {
    @include theme.gradBP();
    font-size: 32px;
  }
}

h1, h2, h3 {
  @include theme.titleFont;

}

h2 {
  @include theme.gradientText(theme.$theme,theme.$theme1);
  font-size: 64px;
  display: inline-block;
}

h3 {
  font-size: 48px;
  font-weight: 400;
}

.item-view {
  &--false {
    display:none;
  }
}

.item {
  height: 480px;
  width: 480px;
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid rgba(255,255,255,0.4);
  border-radius: 8px;
  margin: 16px auto;
  &--standard {
    background: #233248;
  }
  &--master {
    background: #554615;
  }
  &--god {
    background: #a15f1e;
  }
}
.sbtn {
  &--false {
    display: none;
  }
}

.pending {
  $penderH: 48px;
    position: fixed;
    z-index: 14;
    top:0;
    left: 0;
    height: $penderH;
    line-height: $penderH;
    width: 100%;
    background: white;
    color: black;
    text-align: center;
    font-size: $penderH*2/3;
    @include theme.titleFont;
    font-weight: 500;
    &--false {
      display: none;
    }
  }

  .inventory {
    cursor: pointer;
    &--false {
      .inventory-group {
        display: none;
      }
    }
    .inventory-group {

      max-width: 640px;
      margin: 0 auto;
      &__balance {
        
      }
      .inventory-item {
        max-width: 640px;
        padding: 16px;
        box-sizing: border-box;
        border: 1px solid rgba(255,255,255,0.4);
        border-radius: 8px;
        margin-top: 16px;
      }
      .close-btn {
        margin: 12px auto;
      }
    }
    .inventory-nav {
      margin-top: 16px;
      span {
        font-size: 24px;
        margin: 0 16px;
      }
    }

  }

  .store {
    
      button {
        margin: 8px 8px; 
      }
    
  }
